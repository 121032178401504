import { Component, OnDestroy, OnInit } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { ConstantsService } from "../../../services/constants/constants.service";
import { DialogTrackingService } from "../../../services/dialog/dialog-tracking.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NavigationService } from "../../../services/navigation/navigation.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import {
  EntitlementService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonSearch,
  RnOrganizationPackagePoolsForListVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
  RnPackagePoolUsersAssign,
  RnUserWithMutualExclusionForAssignPackageVM,
} from "../../../services/rnapi2-service/models/models";
import { GridCheckLimitColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-check-limit-column-configuration";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHyperlinkColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { PackageConfigInfo } from "../package-inventory/package-config-info";
import { SearchControlConfiguration } from "../search-control/configuration/search-control-configuration";
import { PackageAssignConfigData } from "./package-assign-config-data";
import { UserPackageAssignmentItem } from "./user-package-assignment-item";

@Component({
  selector: "app-package-assignment",
  templateUrl: "./package-assignment.component.html",
  styleUrls: ["./package-assignment.component.scss"],
})
export class PackageAssignmentComponent implements OnInit, OnDestroy {
  public Configuration: PackageAssignConfigData;
  public OrgProfile: RnOrganizationsProfileVM;
  public OrgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  public PkgConfigInfo: PackageConfigInfo;
  public Package: RnOrganizationPackagePoolsForListVM;
  public UserSearch: string;
  public TableConfiguration = new GridConfiguration();
  public searchControlConfiguration: SearchControlConfiguration;

  public AssignButtonText = "ASSIGN";
  public processing = false;
  public packageAssignTooltip: string;

  constructor(
    private config: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private loggedInInfoService: LoggedInInfoService,
    private orgService: OrganizationService,
    private entitlementService: EntitlementService,
    private navigationService: NavigationService,
    private notificationService: NotificationDialogService,
    private constantsService: ConstantsService,
    private dialogTrackservice: DialogTrackingService,
  ) {
    this.Configuration = config.data;
    this.OrgProfile = this.Configuration.OrgProfile;
    this.OrgWlpContactInfo = this.Configuration.OrgWlpContactInfo;
    this.PkgConfigInfo = this.Configuration.PkgConfigInfo;
    this.Package = this.Configuration.Package;
  }

  ngOnInit(): void {
    this.packageAssignTooltip =
      this.constantsService.packageAssignRemoveTooltip;
    this.searchCriteria.AffectedOrganizationId;
    this.searchCriteria.AffectedOrganizationId = this.OrgProfile.ID;
    this.searchCriteria.Id = this.Package.OrganizationPackagePoolID.toString();

    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText = "Search";
    this.searchControlConfiguration.ButtonClass =
      "internal-landing-search-button";
    this.searchControlConfiguration.EditClass = "org-user-search-edit";
    this.searchControlConfiguration.ContainerClass =
      "col-xs-12 col-sm-12 col-md-12 col-lg-12 assignPackageSeach";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;
    this.searchControlConfiguration.MagnifyingGlassClass = "assignUserSearch";
    this.TableConfiguration.ShowCountHeader = false;
    this.TableConfiguration.GridClass =
      "tableHeadingColorClass packageAssignUser ";
    this.TableConfiguration.FirstRow = 0;
    this.TableConfiguration.setUrlData = (params: DataRetrievalParameters) => {
      const sortOrderChange =
        params.SortOrder &&
        params.SortOrder !== "" &&
        this.searchCriteria.OrderByText !== params.SortOrder;
      const isDescendingChange =
        this.searchCriteria.isDescendingOrder !== params.IsDescendingOrder;
      const pageSizeChange = this.searchCriteria.pageSize !== params.PageSize;
      const resetPageIndex =
        sortOrderChange || isDescendingChange || pageSizeChange;

      this.searchCriteria.OrderByText =
        params.SortOrder && params.SortOrder !== ""
          ? params.SortOrder
          : "orderByFullName";
      this.searchCriteria.isDescendingOrder = params.IsDescendingOrder;
      this.searchCriteria.pageNumber = resetPageIndex ? 0 : params.PageNumber;
      this.searchCriteria.pageSize = params.PageSize;

      this.performSearch();
    };
    this.TableConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      return col.Class + (col.FieldName == "UserEmail" ? " break" : "");
    };
    this.TableConfiguration.RnSelectionEnabled = true;

    this.TableConfiguration.ColumnConfiguration = [];
    //Checkbox-selection column
    this.selectionColumnConfig = new GridCheckLimitColumnConfiguration(
      "",
      "",
      "checkboxCol",
      "",
    );
    this.selectionColumnConfig.MaxLimit = () => {
      return this.Package.NumberAllowed - this.Package.NumberUsed;
    };
    this.selectionColumnConfig.GetIsDisabled = (rowData: any) => {
      const userData = rowData as RnUserWithMutualExclusionForAssignPackageVM;
      const isAssignedAddon = userData.RequiredPackagesNames.some((v) =>
        userData.DirectBilledPackages.includes(v),
      );

      // AP-332 - Adding check to make sure that disabling due to mutual exclusion is maintained.
      if (
        userData.RequiredPackagesIds &&
        userData.RequiredPackagesIds.length > 0 &&
        isAssignedAddon &&
        userData.ExclusionType !== "mutex"
      ) {
        userData.ExclusionType = "none";
      }

      if (userData) {
        return userData.ExclusionType !== "none";
      }
      return true;
    };
    this.selectionColumnConfig.Tooltip = (rowData: any) => {
      const userData = rowData as RnUserWithMutualExclusionForAssignPackageVM;
      if (userData) {
        return this.getTooltipForUser(userData);
      }
      return "";
    };
    this.selectionColumnConfig.SelectedItems = [];
    this.selectionColumnConfig.LimitExceeded = () => {
      const message = this.GetExceededLimitMessage();
      this.notificationService.ShowNotification(
        "Not enough packages are available",
        message,
      );
    };

    this.TableConfiguration.ColumnConfiguration.push(
      this.selectionColumnConfig,
    );
    //User's Fullname column
    if (this.loggedInInfoService.loggedInUserHasRights(["VWOTHERSPK"])) {
      const userFullNameCol = new GridHyperlinkColumnConfiguration(
        "FullName",
        "User",
        "userLink",
        "orderByFullName",
      );
      userFullNameCol.GetHref = (rowData: any) => {
        const userData = rowData as RnUserWithMutualExclusionForAssignPackageVM;
        if (userData) {
          if (this.navigationService) {
            return this.navigationService.GetOpenUserUrl(
              userData.UserID,
              userData.OrganizationID,
            );
          }
        }
        return "";
      };
      this.TableConfiguration.ColumnConfiguration.push(userFullNameCol);
    } else {
      this.TableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "FullName",
          "User",
          "userLink",
          "orderByFullName",
        ),
      );
    }

    //User's Email column
    const emailCol = new GridColumnConfiguration(
      "UserEmail",
      "Email Address",
      "pkg-user-list-email-col",
      "orderByEmail",
    );
    this.TableConfiguration.ColumnConfiguration.push(emailCol);

    //User's Package column
    const packageCol = new GridColumnConfiguration(
      "AssignedPackage",
      "Current Assigned Package",
      "pkg-user-assign-package-col",
      "orderByAssignedPackage",
    );
    packageCol.CustomDataRetrieval = (rowData: any) => {
      const userData = rowData as RnUserWithMutualExclusionForAssignPackageVM;
      if (userData) {
        return userData.AssignedPackage && userData.AssignedPackage !== ""
          ? userData.AssignedPackage
          : "-";
      }
      return "";
    };
    this.TableConfiguration.ColumnConfiguration.push(packageCol);

    //User's Organization column
    const orgNameCol = new GridHyperlinkColumnConfiguration(
      "OrganizationName",
      "Organization",
      "userLink",
      "orderByOrganizationName",
    );
    orgNameCol.GetHref = (rowData: any) => {
      const userData = rowData as RnUserWithMutualExclusionForAssignPackageVM;
      if (userData) {
        if (this.navigationService) {
          return this.navigationService.GetOpenOrgUserUrl(
            this.Configuration.TopLevelOrgID,
            userData.OrganizationID,
            "packages",
          );
        }
      }
      return "";
    };
    this.TableConfiguration.ColumnConfiguration.push(orgNameCol);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  public assignPackage(): void {
    this.AssignButtonText = "ASSIGNING";
    this.processing = true;
    const userIds = [];
    this.selectionColumnConfig.SelectedItems.forEach((u) => {
      const user = u as RnUserWithMutualExclusionForAssignPackageVM;
      userIds.push(user.UserID);
    });

    const payload = new RnPackagePoolUsersAssign();
    payload.PackagePool_ID = this.Package.OrganizationPackagePoolID;
    payload.User_IDs = userIds;
    payload.AffectedOrganizationId = this.OrgProfile.ID;

    this.entitlementService
      .apiV2EntitlementAssignpackagetousersPost(payload, "response")
      .subscribe((r) => {
        if (r.body.Success) {
          this.dialogTrackservice.closeDialog(
            this.dialogRef,
            this.selectionColumnConfig.SelectedItems.length,
          );
        }
      });
  }

  public close(): void {
    this.dialogTrackservice.closeDialog(this.dialogRef, false);
  }

  public consumedQuantity(): number {
    return (
      this.Package.NumberUsed + this.selectionColumnConfig.SelectedItems.length
    );
  }

  public remainingQuantity(): number {
    return this.Package.NumberAllowed - this.consumedQuantity();
  }

  public anyUsersSelected(): boolean {
    return this.selectionColumnConfig.SelectedItems.length > 0;
  }

  public searchOccurred(searchText: string): void {
    this.TableConfiguration.Loading = true;
    this.searchCriteria.search = searchText;
    this.searchCriteria.pageNumber = 0;
    this.performSearch();
  }

  private subscriptions = [];
  private searchCriteria = new RnCommonSearch();
  private currentUserList: UserPackageAssignmentItem[] = [];
  public selectionColumnConfig: GridCheckLimitColumnConfiguration;

  private getTooltipForUser(
    userData: RnUserWithMutualExclusionForAssignPackageVM,
  ): string {
    switch (userData.ExclusionType) {
      case "mutex":
        return `This is not available when the <strong>${this.getStringFromStringArray(userData.ExcludingPackageNames)}</strong> add-on is assigned`;
      case "preReq":
        if (userData.RequiredPackagesIds.length > 0) {
          return "You will need to assign QuickBooks Desktop Cloud, Business Cloud or Application Cloud to assign this add-on";
        } else {
          return "You will need to assign either a package or add-on that includes QuickBooks to assign this add-on.";
        }
    }
  }

  private getStringFromStringArray(stringArray: string[]): string {
    if (stringArray.length === 1) {
      return stringArray[0];
    }

    let result = stringArray.slice(0, stringArray.length - 1).join(", ");
    result += " or " + stringArray[stringArray.length - 1];
    return result;
  }

  private performSearch(): void {
    this.selectionColumnConfig.SelectedItems = [];
    this.subscriptions.push(
      this.orgService
        .apiV2OrganizationsGetuserswithmutualexclusionforpackageassignPost(
          this.searchCriteria,
          "response",
        )
        .subscribe((r) => {
          this.currentUserList = [];
          r.body.data.Results.forEach((u) => {
            const item = new UserPackageAssignmentItem(u);
            this.currentUserList.push(item);
          });

          const firstRow =
            this.searchCriteria.pageNumber * this.searchCriteria.pageSize;
          this.TableConfiguration.loadingDataCompleted(
            this.currentUserList,
            r.body.data.TotalNumberRecords,
            firstRow,
            this.searchCriteria.pageNumber,
          );
        }),
    );
  }

  private GetExceededLimitMessage(): string {
    if (this.Package.IsAddOn && !this.Package.IsPurchasable) {
      return `There are no <b>${this.Package.PackageName}</b> add-ons available. Please contact ${this.OrgProfile.PartnerName} at <nobr> ${this.OrgWlpContactInfo.SalesPhone} </nobr> to purchase additional add-ons for your account.`;
    }
    //purchasable
    else if (this.Package.IsAddOn && this.Package.IsPurchasable) {
      return `There are no <b>${this.Package.PackageName}</b> add-ons available. You can unassign add-ons until you have enough or purchase additional copies by clicking the Change Seat Count button.`;
    }
    //package
    //unpurchasable
    else if (!this.Package.IsAddOn && !this.Package.IsPurchasable) {
      //TODO Make more generic
      if (
        this.OrgProfile.PartnerName === "Intuit" &&
        this.Package.PackageSKU === "PKG_QBES_CLOUD"
      ) {
        return `There are no <b>${this.Package.PackageName}</b> packages available. Please contact Intuit at <nobr>1-877-683-3280</nobr> to purchase additional packages for your account.`;
      } else if (
        this.OrgProfile.PartnerName === "Intuit" &&
        this.Package.PackageSKU === "PKG_QBESPLUS_CLOUD"
      ) {
        return `There are no <b>${this.Package.PackageName}</b> packages available. Please contact Intuit at <nobr>1-877-300-7345</nobr> to purchase additional packages for your account.`;
      } //End TODO Make more generic
      else {
        return `There are no <b>${this.Package.PackageName}</b> packages available. Please contact ${this.OrgProfile.PartnerName} at <nobr>${this.OrgWlpContactInfo.SalesPhone}</nobr> to purchase additional packages for your account.`;
      }
    }
    //default text (purchasable)
    else {
      return `There are no <b>${this.Package.PackageName}</b> packages available. You can unassign packages until you have enough or purchase additional copies by clicking the Change Seat Count button.`;
    }
  }
}
