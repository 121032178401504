import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { SelectionService } from "src/app/shared/services/selection/selection.service";
import {
  RnsidebarService,
  SidebarComponents,
} from "src/app/shared/services/sidebar/rnsidebar.service";
import { Guid } from "src/app/shared/utilities/guid/guid.utilities";
import { TableSelectable } from "../../../../core/interfaces/table-selectable";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { LicenseService } from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonIdMulti,
  RnCommonSearch,
  RnLicensesWithUsersVM,
  RnLicenseUpdateDTO,
  RnOrganizationsProfileVM,
  RnUserLicensesRemoveUser,
  RnUsersForOrgSubOrgSearchForLicenseVM,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GridCheckLimitColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-check-limit-column-configuration";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHyperlinkColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { LicenseAssignConfig } from "../license-assign/license-assign-config";
import { SignalrPollingBaseMessage } from "../../../../core/models/signalr/signalr-polling-base-message";
import { SignalRService } from "../../../notifications/signalr.service";
import { SignalrPollingService } from "../../../services/signalr-polling/signalr-polling.service";
import { ProcessingEventData } from "../../../../core/models/processing-event-data";
import { processEventStateTypes } from "../../../../core/enums/processEventStateTypes";
import { NavigationService } from "../../../services/navigation/navigation.service";
import { RnDialogService } from "src/app/shared/services/dialog/rndialog.service";

class LicenseUserData
  extends RnUsersForOrgSubOrgSearchForLicenseVM
  implements TableSelectable
{
  RnTableSelected: boolean;
}
@Component({
  selector: "app-license-details",
  templateUrl: "./license-details.component.html",
  styleUrls: ["./license-details.component.scss"],
})
export class LicenseDetailsComponent implements OnInit, OnDestroy {
  @Input() LicenseId: string;
  @Output() ChangeState = new EventEmitter<any>();
  @Output() ProcessingEvent = new EventEmitter<ProcessingEventData>();

  organizationId: number;
  license: RnLicensesWithUsersVM = new RnLicensesWithUsersVM();
  licenseGridConfiguration: GridConfiguration = new GridConfiguration();
  autoAssignInProgress = false;
  isEditing = false;
  selectedOrgStatus: string;
  private signalRHandlerID: Guid;
  formGroup: FormGroup;
  refreshUserGuid: Guid;
  canShowActions = false;
  canShowEdit = false;
  isUnassignUsersDisabled = true;
  canAssignUsersToLicense = false;
  private eventData = new ProcessingEventData();
  public isAssigned = false;
  public counter = 0;

  constructor(
    private route: ActivatedRoute,
    private licenseService: LicenseService,
    private formBuilder: FormBuilder,
    private toastService: RnToastService,
    private rnsidebarService: RnsidebarService,
    private selectionService: SelectionService,
    private signalrPollingService: SignalrPollingService,
    private signalRService: SignalRService,
    private navigationService: NavigationService,
    private rnDialogService: RnDialogService,
  ) {}

  ngOnInit(): void {
    this.eventData.type = processEventStateTypes.updating;
    this.ProcessingEvent.emit(this.eventData);
    this.formGroup = this.formBuilder.group({
      license1: ["", Validators.required.bind(Validators)],
      license2: ["", Validators.required.bind(Validators)],
    });

    this.subscriptions.push(
      this.route.queryParamMap.subscribe((p) => {
        this.organizationId = Number(
          p.has("subOrgId") ? p.get("subOrgId") : this.route.snapshot.params.id,
        );
      }),
    );

    const selectedOrgProfile =
      this.selectionService.SelectedOrgProfile.subscribe((p) => {
        this.selectedOrg = p;
        this.selectedOrgStatus = this.selectedOrg?.Status;
      });
    this.subscriptions.push(selectedOrgProfile);
    this.rnCommonSearch.AffectedOrganizationId = this.organizationId;
    this.rnCommonSearch.Id = this.LicenseId;
    this.rnCommonSearch.pageNumber = 0;
    this.rnCommonSearch.pageSize = 10;
    this.rnCommonSearch.isDescendingOrder = false;
    this.rnCommonSearch.OrderByText = "orderByFullName";

    const payload: RnCommonIdMulti = {
      Ids: [this.LicenseId],
      AffectedOrganizationId: this.organizationId,
    };
    const licenseGetByID = this.licenseService
      .apiV2LicensesLicensesgetbyidPost(payload)
      .subscribe((r) => {
        this.license = r.data[0];
        this.setupGridColumns();
        this.performSearch();
        this.canShowActions = this.canShowEdit = this.licenseIsByol();
        this.canAssignUsersToLicense = this.getCanAssignUsersToLicense();
      });
    this.subscriptions.push(licenseGetByID);
    this.signalrPollingService.AddOrgTrackingInfo(this.organizationId);
    this.signalRService.messages.subscribe((message) => {
      const notification = JSON.parse(message) as SignalrPollingBaseMessage;
      if (
        notification &&
        notification.Payload &&
        notification.Payload.AreaDataType.toUpperCase() ===
          "LICENSE_ASSIGN_PAYLOAD" &&
        notification.Payload.Area.toUpperCase() == "ORG" &&
        notification.Payload.AreaID == this.organizationId
      ) {
        this.performSearch();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this.signalRHandlerID) {
      this.signalrPollingService.RemoveMessageHandler(this.signalRHandlerID);
    }
    this.signalrPollingService.RemoveOrgTrackingInfo(this.organizationId);
  }

  setupGridColumns() {
    this.licenseGridConfiguration.FirstRow = 0;
    this.licenseGridConfiguration.ColumnConfiguration = [];
    this.licenseGridConfiguration.ShowCountHeader = true;
    this.licenseGridConfiguration.HeaderClass = "userCount";
    this.licenseGridConfiguration.GridClass =
      "tableHeadingColorClass tableRowHeight";
    this.licenseGridConfiguration.CountHeaderItemsName =
      "Users Assigned to License";
    this.licenseGridConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      return col.Class + (col.FieldName == "UserEmail" ? " break" : "");
    };

    this.selectionColumnConfig = new GridCheckLimitColumnConfiguration(
      "",
      "",
      "checkboxCol",
      "",
    );
    this.selectionColumnConfig.SelectAllChange = (
      $event,
      allSelected: boolean,
    ) => {
      if (allSelected) {
        this.selectionColumnConfig.SelectedItems = [];
        this.currUsers.forEach((l) => {
          const d = l as LicenseUserData;
          d.RnTableSelected = true;
          this.selectionColumnConfig.SelectedItems.push(d);
        });
        this.isUnassignUsersDisabled =
          this.licenseGridConfiguration.TotalRecords == 0 ? true : false;
      } else {
        this.selectionColumnConfig.SelectedItems.forEach((i) => {
          i.RnTableSelected = false;
        });
        this.selectionColumnConfig.SelectedItems = [];
        this.isUnassignUsersDisabled = true;
      }
    };
    this.selectionColumnConfig.SelectAllAvailable = true;
    this.selectionColumnConfig.SelectedItems = [];
    this.selectionColumnConfig.CheckChange = ($event, rowData: any) => {
      const l = rowData as LicenseUserData;
      if (l.RnTableSelected) {
        this.isUnassignUsersDisabled = false;
        this.licenseGridConfiguration.RnSelectAll =
          this.currUsers.length ===
          this.selectionColumnConfig.SelectedItems.length;
      } else {
        this.isUnassignUsersDisabled =
          this.selectionColumnConfig.SelectedItems.length <= 0;
        this.licenseGridConfiguration.RnSelectAll = false;
      }
    };

    this.licenseGridConfiguration.ColumnConfiguration.push(
      this.selectionColumnConfig,
    );
    this.licenseGridConfiguration.RnSelectionEnabled = true;
    this.licenseGridConfiguration.RnSelectAll = false;

    const nameColumn = new GridHyperlinkColumnConfiguration(
      "FullName",
      "User",
      "",
      "orderByFullName",
    );
    nameColumn.GetHref = (rowData: any) => {
      const userData = rowData as LicenseUserData;
      if (userData && this.navigationService) {
        return this.navigationService.GetOpenUserUrl(
          userData.UserID,
          userData.OrganizationID,
        );
      } else {
        return "";
      }
    };
    this.licenseGridConfiguration.ColumnConfiguration.push(nameColumn);
    this.licenseGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "UserEmail",
        "Email Address",
        "",
        "orderByUserEmail",
      ),
    );
    this.licenseGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "AssignedPackage",
        "Package",
        "",
        "orderByAssignedPackage",
      ),
    );
    this.licenseGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "OrganizationName",
        "Organization",
        "",
        "orderByOrganizationName",
      ),
    );

    this.licenseGridConfiguration.setUrlData = (
      params: DataRetrievalParameters,
    ) => {
      this.rnCommonSearch.pageNumber =
        ((params.SortOrder === "" &&
          this.rnCommonSearch.OrderByText === "orderByFullName") ||
          this.rnCommonSearch.OrderByText === params.SortOrder) &&
        this.rnCommonSearch.isDescendingOrder === params.IsDescendingOrder &&
        this.rnCommonSearch.pageSize === params.PageSize
          ? params.PageNumber
          : 0;
      this.rnCommonSearch.pageSize = params.PageSize;
      this.rnCommonSearch.isDescendingOrder = params.IsDescendingOrder;
      this.rnCommonSearch.OrderByText = params.SortOrder
        ? params.SortOrder
        : "orderByFullName";
      this.rnCommonSearch.Id = this.LicenseId;
      this.rnCommonSearch.AffectedOrganizationId = this.organizationId;
      this.licenseGridConfiguration.RnSelectAll = false;
      this.isUnassignUsersDisabled = true;
      this.performSearch();
    };
  }

  public rowSelected(event): void {
    this.selectionColumnConfig.SelectedItems = event;
  }

  goBackToLicenses() {
    this.ChangeState.emit({ state: "list" });
  }

  toggleLicenseAutoAssign(event) {
    this.eventData.type = processEventStateTypes.updating;
    this.ProcessingEvent.emit(this.eventData);
    this.autoAssignInProgress = true;
    this.license.IsAutoAssignable = !this.license.IsAutoAssignable;
    // do the API thing
    const payload: RnLicenseUpdateDTO = {
      AffectedOrganizationId: this.organizationId,
      LicensesID: Number(this.LicenseId),
      ItemsToUpdate: [
        {
          Key: "IsAutoProvision",
          Value: this.license.IsAutoAssignable.toString(),
        },
      ],
      IsByol: this.license.Type == "BYOL",
    };
    const licenseUpdatePost = this.licenseService
      .apiV2LicensesUpdatePost(payload)
      .subscribe(
        (r) => {
          this.autoAssignInProgress = true;
        },
        (err) => {
          this.autoAssignInProgress = false;
          this.eventData.type = processEventStateTypes.completed;
          this.ProcessingEvent.emit(this.eventData);
        },
        () => {
          this.autoAssignInProgress = false;
          this.eventData.type = processEventStateTypes.completed;
          this.ProcessingEvent.emit(this.eventData);
        },
      );
    this.subscriptions.push(licenseUpdatePost);
    this.autoAssignInProgress = false;
  }

  licenseIsByol(): boolean {
    return this.license && this.license.Type == "BYOL";
  }

  editLicense() {
    this.rnDialogService.EditLicenseDetailDialog(this.license);
  }

  getCanAssignUsersToLicense() {
    if (this.license.Type == "Leased") {
      if (this.currUsers && this.currUsers?.length === 1) {
        return false;
      }
      if (!this.currUsers && this.license.Users.length === 0) {
        return true;
      }
    }
    if (this.selectedOrg && this.license)
      return (
        this.selectedOrgStatus !== "Suspended" &&
        this.license &&
        (this.license.Type != "Leased" ||
          this.license.LicenseCount > this.license.Users.length)
      );
  }

  saveChanges() {
    const changes: any[] = [];
    const newLicense1 = this.formGroup.controls["license1"].value.replace(
      /\D/g,
      "",
    );
    const newLicense2 = this.formGroup.controls["license2"].value.replace(
      /\D/g,
      "",
    );
    if (newLicense1 != this.license.License1) {
      changes.push({ Key: "License1", Value: newLicense1 });
    }
    if (newLicense2 != this.license.License2) {
      changes.push({ Key: "License2", Value: newLicense2 });
    }
    if (changes.length <= 0) {
      return;
    }
    this.isEditing = false;
    this.license.License1 = newLicense1;
    this.license.License2 = newLicense2;

    const payload: RnLicenseUpdateDTO = {
      AffectedOrganizationId: this.organizationId,
      LicensesID: Number(this.LicenseId),
      ItemsToUpdate: changes,
    };
    const licenseUpdatePost = this.licenseService
      .apiV2LicensesUpdatePost(payload)
      .subscribe((r) => {
        console.log("License updated successfully");
      });
    this.subscriptions.push(licenseUpdatePost);
  }

  cancelChanges() {
    this.isEditing = false;
  }

  unassignUsers() {
    const payload: RnUserLicensesRemoveUser = {
      AffectedOrganizationId: this.organizationId,
      LicenseID: Number(this.LicenseId),
      UserIDs: (
        this.selectionColumnConfig
          .SelectedItems as RnUsersForOrgSubOrgSearchForLicenseVM[]
      ).map((s) => {
        return Number(s.UserID);
      }),
    };
    this.eventData.type = processEventStateTypes.updating;
    this.ProcessingEvent.emit(this.eventData);

    const removeLicenseFromUserPost = this.licenseService
      .apiV2LicensesRemovelicensefromusersPost(payload)
      .subscribe((r) => {
        if (r && r.Success) {
          this.signalRHandlerID = this.signalrPollingService.AddMessageHandler(
            (message) => {
              const notification = JSON.parse(
                message,
              ) as SignalrPollingBaseMessage;
              if (
                notification &&
                notification.Payload &&
                notification.Payload.AreaDataType.toUpperCase() ===
                  "LICENSE_ASSIGN_PAYLOAD" &&
                notification.Payload.Area.toUpperCase() == "ORG" &&
                notification.Payload.AreaID == this.organizationId
              ) {
                const removed =
                  notification.Payload.AreaData.includes("Removed");
                if (removed) {
                  this.licenseGridConfiguration.RnSelectAll = false;
                  this.selectionColumnConfig.SelectedItems = [];
                  // reset disable state for button after remove
                  this.isUnassignUsersDisabled = true;

                  if (this.license.Type == "Leased") {
                    this.canAssignUsersToLicense =
                      !this.getCanAssignUsersToLicense();
                    this.isUnassignUsersDisabled =
                      !this.getCanAssignUsersToLicense();
                  }
                }
                // }else {
                //   this.toastService.showError("There was a problem removing the user(s) from the license.");
                // }
              }
            },
          );
        }
      });
    this.subscriptions.push(removeLicenseFromUserPost);
  }

  assignUsers(): void {
    const dialogConfig = new LicenseAssignConfig();
    dialogConfig.LicenseID = Number(this.LicenseId);
    dialogConfig.OrganizationID = this.selectedOrg.ID;
    this.rnsidebarService.trigger(
      SidebarComponents.licenseAssign,
      dialogConfig,
      "assignUsers",
    );

    this.signalRHandlerID = this.signalrPollingService.AddMessageHandler(
      (message) => {
        const notification = JSON.parse(message) as SignalrPollingBaseMessage;
        if (
          notification &&
          notification.Payload &&
          notification.Payload.AreaDataType.toUpperCase() ===
            "LICENSE_ASSIGN_PAYLOAD" &&
          notification.Payload.Area.toUpperCase() == "ORG" &&
          notification.Payload.AreaID == this.organizationId
        ) {
          const added = notification.Payload.AreaData.includes("Assigned");
          if (added) {
            this.isAssigned = true;
            this.licenseGridConfiguration.RnSelectAll = false;
            this.selectionColumnConfig.SelectedItems = [];
            if (this.license.Type == "Leased") {
              this.canAssignUsersToLicense = !this.getCanAssignUsersToLicense();
              this.isUnassignUsersDisabled = !this.getCanAssignUsersToLicense();
            }
          }
        }
      },
    );
  }

  private performSearch(result?: number) {
    this.eventData.type = processEventStateTypes.updating;
    this.ProcessingEvent.emit(this.eventData);
    const getOrguserandSubExclude = this.licenseService
      .apiV2LicensesGetlicenseusersPost(this.rnCommonSearch, "response")
      .subscribe(
        (r) => {
          this.currUsers = r.body.data.Results;
          this.licenseGridConfiguration.loadingDataCompleted(
            r.body.data.Results,
            r.body.data.TotalNumberRecords,
            this.rnCommonSearch.pageNumber * this.rnCommonSearch.pageSize,
            this.rnCommonSearch.pageNumber,
          );
          this.eventData.type = processEventStateTypes.completed;
          this.ProcessingEvent.emit(this.eventData);
          if (
            this.counter !== r.body.data.TotalNumberRecords &&
            this.isAssigned === true
          ) {
            //added counter to make sure the toast do not get updated on each signalR record, added the flag to make sure the toast only appears on assign not each grid refresh
            if (this.license.Type == "Leased") {
              this.canAssignUsersToLicense = this.getCanAssignUsersToLicense();
            }
            this.counter = r.body.data.TotalNumberRecords;
            this.toastService.showSuccess(
              r.body.data.TotalNumberRecords.toString() +
                " Users have been assigned to the " +
                this.license.ItemName +
                " License.",
            );
          }
        },
        (error) => {
          console.error(error);
          this.eventData.type = processEventStateTypes.completed;
          this.ProcessingEvent.emit(this.eventData);
        },
      );
    this.subscriptions.push(getOrguserandSubExclude);
  }

  private rnCommonSearch: RnCommonSearch = new RnCommonSearch();
  private currUsers: RnUsersForOrgSubOrgSearchForLicenseVM[];
  private selectionColumnConfig: GridCheckLimitColumnConfiguration;
  private subscriptions: Subscription[] = [];
  selectedOrg: RnOrganizationsProfileVM;
}
