<div
  #containerForm="ngForm"
  [formGroup]="userDetailsForm"
  name="userDetailsForm"
  class="userDetails"
>
  @if (showLoader()) {
    <app-loader [text]="getLoaderText()"></app-loader>
  }

  <!-- First name field -->
  <div
    class="p-field firstNameDiv"
    [ngClass]="{
      'has-error': !f.firstName.valid && f.firstName.touched,
    }"
  >
    <span class="userInfoCategory">First Name</span><br />
    <input
      class="form-control ui-inputtext"
      formControlName="firstName"
      placeholder="{{ f.FirstName }}"
      maxlength="40"
      required
      data-testid="userfnameedit"
      [readonly]="userIsReadOnly || !canEditContactInfo"
      (focus)="$event.target.select()"
    />
    <div>
      @if (
        f.firstName.touched && (!this.user.FirstName || !f.firstName?.valid)
      ) {
        <div data-testid="firstNameError" class="help-block">
          Required field
        </div>
      }
      @if (f.firstName.touched && f.firstName.errors?.maxlength) {
        <div class="help-block">
          First Name is too long (must be 40 characters or less)
        </div>
      }
    </div>
  </div>

  <!-- Last name field -->
  <div
    class="p-field lastNameDiv"
    [ngClass]="{
      'has-error': !f.lastName.valid && f.lastName.touched,
    }"
  >
    <span class="userInfoCategory">Last Name</span><br />
    <input
      class="form-control ui-inputtext"
      placeholder="{{ f.LastName }}"
      formControlName="lastName"
      maxlength="50"
      required
      data-testid="userlnameedit"
      [readonly]="userIsReadOnly || !canEditContactInfo"
      (focus)="$event.target.select()"
    />
    <div>
      @if (f.lastName.touched && (!this.user.LastName || !f.lastName?.valid)) {
        <div data-testid="lastNameError" class="help-block">Required field</div>
      }
      @if (f.lastName.touched && f.lastName.errors?.maxlength) {
        <div class="help-block">
          Last Name is too long (must be 50 characters or less)
        </div>
      }
    </div>
  </div>
  <div>
    <!-- Phone number field -->
    <div class="p-b-sm">
      <label for="phoneNumber" class="userInfoCategory">Phone Number </label>
      <div class="pair">
        <div class="left">
          <div [ngClass]="{ 'has-error': !f.countryCode.valid }">
            <rw-single-select
              id="{{ idPrefix }}country-code-select"
              [items]="countryCodes"
              bindLabel="CountryName"
              bindValue="CountryCode"
              searchable="false"
              [placeholder]="'Select a Country Code'"
              [arialabel]="'Select Country Code'"
              datapendoid="{{ idPrefix }}country-code-select"
              datatestid="{{ idPrefix }}country-code-select"
              formControlName="countryCode"
              [isReadOnly]="userIsReadOnly || !canEditContactInfo"
              (valueChanged)="updatePhoneValidationForCountry($event)"
            ></rw-single-select>
          </div>
        </div>
        <div allowedForSelf="true" class="right">
          <div
            [ngClass]="{
              'has-error': f.phoneNumber.invalid && f.phoneNumber.touched,
            }"
          >
            <input
              class="form-control"
              id="phoneNumber"
              formControlName="phoneNumber"
              type="text"
              defaultText="{{ user?.PhoneNumber }}"
              [mask]="phoneNumberMask"
              [readonly]="userIsReadOnly || !canEditContactInfo"
              data-testid="userDetailPhoneNumber"
              (blur)="canSave()"
            />

            @if (!showLoader()) {
              <div>
                @if (f.phoneNumber.touched && f.phoneNumber.errors?.required) {
                  <div class="help-block">Required field</div>
                }

                @if (
                  f.phoneNumber.touched && f.phoneNumber.hasError("minlength")
                ) {
                  <div class="help-block">Minimum 10 digits required</div>
                }

                @if (
                  f.phoneNumber.touched && f.phoneNumber.hasError("maxlength")
                ) {
                  <div class="help-block">Only 10 digits required</div>
                }

                @if (
                  f.phoneNumber.touched && f.phoneNumber.hasError("pattern")
                ) {
                  <div class="help-block">
                    This field cannot contain letters and special characters.
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
    <!-- Email field -->
    <div
      class="p-field email"
      [ngClass]="{
        'has-error': !f.email.valid && f.email?.dirty,
      }"
    >
      <app-email-verfication
        [location]="'editUser'"
        [emailLabel]="'EMAIL ADDRESS'"
        [userDetailsForm]="userDetailsForm"
        [userProfile]="user"
        [showStaticEmail]="userIsReadOnly || !canEditContactInfo"
        [inEditingMode]="!userIsReadOnly && canEditContactInfo"
        [labelClass]="'emailLabel'"
        [profileId]="user?.UserID"
        (emailUnique)="checkEmailUnique($event)"
        data-testid="userDetailEmail"
      >
      </app-email-verfication>
    </div>
    <div class="pair">
      <div class="left">
        <!-- Organization field -->
        <span class="userInfoCategory">Organization </span>
        <input
          type="text"
          class="form-control"
          placeholder="{{ user?.OrganizationName }}"
          [readonly]="true"
        />
      </div>
      <div class="right">
        <!-- User role field -->
        <span class="userInfoCategory">Role </span>
        @if (canEditUserRole) {
          <rw-single-select
            id="{{ idPrefix }}user-role-select"
            [items]="visibleRoles"
            bindLabel="Name"
            bindValue="ID"
            searchable="false"
            placeholder="Select User Role"
            arialabel="Select Country Code"
            datapendoid="{{ idPrefix }}user-role-select"
            datatestid="{{ idPrefix }}user-role-select"
            [readonly]="userIsReadOnly"
            formControlName="role"
            (valueChanged)="canSave()"
          ></rw-single-select>
        } @else {
          <input
            type="text"
            class="form-control"
            placeholder="{{ this.user?.UserType }}"
            ng-readonly="true"
            [readonly]="true"
          />
        }
      </div>
    </div>
    <br />

    <div class="p-b-sm">
      <div class="pair">
        <div class="left">
          <!-- Support pin field -->
          <label for="supportPIN" class="userInfoCategory"
            >Support PIN Number
          </label>
          <app-icon
            source="rn-help"
            tooltip-placement="right"
            pTooltip="<div><b>Support PIN</b> is used when calling Rightworks to verify your account</div>"
            [escape]="false"
          ></app-icon
          ><br />
          <div
            [ngClass]="{
              'has-error': f.supportPIN.invalid,
            }"
          >
            <input
              id="supportPIN"
              formControlName="supportPIN"
              class="form-control"
              type="text"
              [mask]="supportPINMask"
              [required]="isPINRequired"
              value="{{ user?.SupportPIN }}"
              data-testid="userDetailSupportPin"
              [readonly]="!canEditSupportPin || userIsReadOnly"
              (blur)="canSave()"
            />
            <div>
              @if (!showLoader()) {
                <div>
                  @if (f.supportPIN.errors?.required) {
                    <div class="help-block">Required field</div>
                  }
                  @if (f.supportPIN.invalid && !f.supportPIN.errors?.required) {
                    <div class="help-block">
                      Support PIN must be 4 digits long
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <div class="right">
          <div class="p-b-sm">
            <!-- XID field -->
            <label for="xid" class="userInfoCategory">XID </label>
            <app-icon
              source="rn-help"
              tooltip-placement="right"
              [pTooltip]="xidHelp"
              [escape]="false"
            ></app-icon
            ><br />
            <div
              [ngClass]="{
                'has-error': f.xid.invalid && f.xid.touched,
              }"
            >
              <input
                [readonly]="!canEditXID"
                class="form-control"
                id="xid"
                formControlName="xid"
                maxlength="50"
                type="text"
                data-testid="userDetailXid"
                (blur)="canSave()"
              />

              @if (f.xid.touched && f.xid.errors?.maxlength) {
                <div class="help-block">
                  XID is too long (must be 50 characters or less)
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cancelSaveButtons">
    <app-button
      id="btnCancel"
      label="Cancel"
      buttonStyle="addUserTeritary"
      (clickActionEmitter)="this.cancel()"
    >
    </app-button>
    <app-button
      id="btnSubmit"
      label="Save"
      [disabled]="!this.canSave()"
      showProcessing="true"
      [processing]="isSaving"
      buttonStyle="primary"
      (clickActionEmitter)="saveUserInfo()"
    >
    </app-button>
  </div>
</div>
