import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { TableSelectable } from "src/app/core/interfaces/table-selectable";
import { DataRetrievalParameters } from "src/app/core/models/data-retrieval-parameters";
import {
  RnLicensesWithUsersVM,
  RnUsersForOrgSubOrgSearchForLicenseVM,
  RnCommonIdMulti,
  RnCommonSearch,
} from "../../../services/rnapi2-service/models/models";
import { GridCheckLimitColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-check-limit-column-configuration";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridMultiValueColumnConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-multi-value-custom-configuration";
import { GridHtmlColumnConfiguration } from "../../../../shared/components/third-party-wrappers/grid/configuration/grid-html-column-configuration";
import { LicenseAssignConfig } from "./license-assign-config";
import { OrganizationService } from "src/app/shared/services/rnapi2-service/apis/organization.service";
import { RnOrganizationUsersForLicenseSearch } from "src/app/shared/services/rnapi2-service/models/organizationUsersForLicenseSearch-d";
import { LicenseService } from "src/app/shared/services/rnapi2-service/apis/license.service";
import { Subject, Subscription } from "rxjs";
import { SearchControlConfiguration } from "../search-control/configuration/search-control-configuration";
import { RnUserLicensesAssignUsers } from "src/app/shared/services/rnapi2-service/models/userLicensesAssignUsers-d";
import { RnsidebarService } from "src/app/shared/services/sidebar/rnsidebar.service";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { NavigationService } from "../../../services/navigation/navigation.service";

class LicenseUserData
  extends RnUsersForOrgSubOrgSearchForLicenseVM
  implements TableSelectable
{
  RnTableSelected: boolean;
}

@Component({
  selector: "app-license-assign",
  templateUrl: "./license-assign.component.html",
  styleUrls: ["./license-assign.component.scss"],
})
export class LicenseAssignComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  @Input() data: LicenseAssignConfig;
  @Output() componentLoaded = new EventEmitter<boolean>();
  @Input() LicenseId: string;
  searchText = "";
  clearSearch: Subject<void> = new Subject<void>();
  organizationId: number;
  license: RnLicensesWithUsersVM = new RnLicensesWithUsersVM();
  assigedLicenseGridConfiguration: GridConfiguration = new GridConfiguration();
  public TableConfiguration = new GridConfiguration();
  autoAssignInProgress = false;
  isEditing = false;
  isProcessing = false;
  searchControlConfiguration: SearchControlConfiguration;
  userAssignBtnDisabled = true;
  selectionColumnConfig: GridCheckLimitColumnConfiguration;

  constructor(
    private organizationService: OrganizationService,
    private rnsidebarService: RnsidebarService,
    private licenseService: LicenseService,
    private orgService: OrganizationService,
    private notificationDialogService: NotificationDialogService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.componentLoaded.emit(true);
    this.searchControlConfiguration = new SearchControlConfiguration();
    this.searchControlConfiguration.EditFieldPlaceholderText = "Search users";
    this.searchControlConfiguration.ButtonClass =
      "internal-landing-search-button";
    this.searchControlConfiguration.EditClass = "";
    this.searchControlConfiguration.ContainerClass =
      "col-xs-12 col-sm-12 col-md-12 col-lg-12 searchDiv";
    this.searchControlConfiguration.ShowMagnifyingGlassIcon = true;
    this.searchControlConfiguration.MagnifyingGlassClass = "search-icon-test";

    if (this.data) {
      this.rnOrganizationLicensesSearch.AffectedOrganizationId =
        this.data.OrganizationID;
      this.rnOrganizationLicensesSearch.Id =
        this.data.OrganizationID.toString();
      this.rnOrganizationLicensesSearch.LicenseID = this.data.LicenseID;
      this.rnOrganizationLicensesSearch.pageNumber = 0;
      this.rnOrganizationLicensesSearch.pageSize = 10;
      this.rnOrganizationLicensesSearch.isDescendingOrder = false;
      this.rnOrganizationLicensesSearch.OrderByText = "orderByFullName";
      const payload: RnCommonIdMulti = {
        Ids: [this.data.LicenseID.toString()],
        AffectedOrganizationId: this.data.OrganizationID,
      };
      const licenseGetByID = this.licenseService
        .apiV2LicensesLicensesgetbyidPost(payload)
        .subscribe((r) => {
          this.license = r.data[0];
          if (this.license.Type === "Leased") {
            this.selectionColumnConfig.MaxLimit = () => {
              return this.license.LicenseCount;
            };

            const payload = new RnCommonSearch();
            payload.search = "rn_direct_sales_phone_number";
            this.subscriptions.push(
              this.orgService
                .apiV2OrganizationsGetconfigurablevaluePost(payload)
                .subscribe(() => {
                  const message = `
            <p>You have reached the maximum number of users that can be associated to this leased license. To fix this, you can:</p>
            <ul>
              <li>Remove users from this license to make room using the Unassign Users button</li>
            </ul>
            `;
                  this.selectionColumnConfig.LimitExceeded = () => {
                    this.notificationDialogService.ShowNotification(
                      "Leased License Limit",
                      message,
                    );
                  };
                }),
            );
          }
        });

      this.subscriptions.push(licenseGetByID);
      this.performSearch();
      this.loadGridColumns();
      this.searchLoad = false;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  loadGridColumns() {
    this.assigedLicenseGridConfiguration.FirstRow = 0;
    this.assigedLicenseGridConfiguration.ColumnConfiguration = [];
    this.assigedLicenseGridConfiguration.ShowCountHeader = false;
    this.assigedLicenseGridConfiguration.GridClass = "tableHeadingColorClass";
    this.assigedLicenseGridConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      return col.Class + (col.FieldName == "UserEmail" ? " break" : "");
    };
    this.selectionColumnConfig = new GridCheckLimitColumnConfiguration(
      "",
      "",
      "checkboxCol",
      "",
    );
    this.selectionColumnConfig.GetIsDisabled = (rowData: any) => {
      const licenseData = rowData as LicenseUserData;
      if (!licenseData.AssignedPackage) return true;
    };
    this.selectionColumnConfig.Tooltip = (rowData: any) => {
      const licenseData = rowData as LicenseUserData;
      if (!licenseData.AssignedPackage) {
        return "A user needs to be assigned a package before they can have a license assigned to them.";
      }
      return "";
    };
    this.selectionColumnConfig.SelectAllChange = (
      $event,
      allSelected: boolean,
    ) => {
      if (allSelected) {
        this.currUsers.forEach((l) => {
          if (
            this.selectionColumnConfig.SelectedItems.filter((i) => {
              const t = i as LicenseUserData;
              return t.UserID === l.UserID;
            }).length === 0 &&
            l.AssignedPackage
          ) {
            if (
              this.license.Type !== "Leased" ||
              this.selectionColumnConfig.SelectedItems.length <
                this.license.LicenseCount
            ) {
              const d = l as LicenseUserData;
              d.RnTableSelected = true;
              this.selectionColumnConfig.SelectedItems.push(d);
            }
          }
        });
      } else {
        this.selectionColumnConfig.SelectedItems.forEach((i) => {
          i.RnTableSelected = false;
        });
        this.selectionColumnConfig.SelectedItems = [];
      }
      this.userAssignBtnDisabled =
        this.selectionColumnConfig.SelectedItems.length <= 0;
    };
    this.selectionColumnConfig.SelectAllAvailable = true;
    this.selectionColumnConfig.SelectedItems = [];
    this.selectionColumnConfig.CheckChange = ($event, rowData: any) => {
      const l = rowData as LicenseUserData;
      if (l.RnTableSelected) {
        const selectableCount = this.currUsers.filter((c) => {
          return (
            l.AssignedPackage && this.rnOrganizationLicensesSearch.pageSize
          );
        }).length;
        const selectioncount = this.selectionColumnConfig.SelectedItems.filter(
          (c) => {
            return (
              l.AssignedPackage && this.rnOrganizationLicensesSearch.pageSize
            );
          },
        ).length;
        this.assigedLicenseGridConfiguration.RnSelectAll =
          selectableCount === selectioncount;
      } else {
        this.assigedLicenseGridConfiguration.RnSelectAll = false;
      }
      this.userAssignBtnDisabled =
        this.selectionColumnConfig.SelectedItems.length <= 0;
    };
    this.assigedLicenseGridConfiguration.ColumnConfiguration.push(
      this.selectionColumnConfig,
    );
    this.assigedLicenseGridConfiguration.RnSelectionEnabled = true;
    this.assigedLicenseGridConfiguration.RnSelectAll = false;
    const username = new GridMultiValueColumnConfiguration(
      ["FullName", "UserEmail"],
      "Name",
      ["userslist-multi-value-top", "userslist-multi-value-bottom"],
      "orderByFullName",
      true,
    );
    username.GetHref = (rowData: any) => {
      const userData = rowData as LicenseUserData;
      if (userData && this.navigationService) {
        return this.navigationService.GetOpenUserUrl(
          userData.UserID,
          userData.OrganizationID,
        );
      } else {
        return "";
      }
    };
    this.assigedLicenseGridConfiguration.ColumnConfiguration.push(username);
    const multifactor = new GridHtmlColumnConfiguration(
      "MFA",
      "Security (MFA)",
      null,
      null,
    );
    multifactor.GetHtmlDataForColumn = (dataRow: any) => {
      if (dataRow.UserStateName === "Processing") {
        return "";
      } else {
        return dataRow.MfaEnabled ? "Enabled" : "Disabled";
      }
    };
    this.assigedLicenseGridConfiguration.ColumnConfiguration.push(multifactor);
    this.assigedLicenseGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "AssignedPackage",
        "Assigned Package",
        "",
        "orderByAssignedPackage",
      ),
    );
    this.assigedLicenseGridConfiguration.ColumnConfiguration.push(
      new GridColumnConfiguration(
        "OrganizationName",
        "Organization",
        "",
        "orderByOrganizationName",
      ),
    );

    this.assigedLicenseGridConfiguration.setUrlData = (
      params: DataRetrievalParameters,
    ) => {
      const sortOrderChange =
        params.SortOrder &&
        params.SortOrder !== "" &&
        this.rnOrganizationLicensesSearch.OrderByText !== params.SortOrder;
      const isDescendingChange =
        this.rnOrganizationLicensesSearch.isDescendingOrder !==
        params.IsDescendingOrder;
      const pageSizeChange =
        this.rnOrganizationLicensesSearch.pageSize !== params.PageSize;
      const resetPageIndex =
        sortOrderChange || isDescendingChange || pageSizeChange;
      this.rnOrganizationLicensesSearch.OrderByText =
        params.SortOrder && params.SortOrder !== ""
          ? params.SortOrder
          : "orderByFullName";
      this.rnOrganizationLicensesSearch.isDescendingOrder =
        params.IsDescendingOrder;
      this.rnOrganizationLicensesSearch.pageNumber = resetPageIndex
        ? 0
        : params.PageNumber;
      this.assigedLicenseGridConfiguration.RnSelectAll = false;
      this.selectionColumnConfig.SelectedItems = [];
      this.rnOrganizationLicensesSearch.pageSize = params.PageSize;
      this.rnOrganizationLicensesSearch.Id =
        this.data.OrganizationID.toString();
      this.rnOrganizationLicensesSearch.AffectedOrganizationId =
        this.data.OrganizationID;
      this.rnOrganizationLicensesSearch.LicenseID = this.data.LicenseID;
      this.userAssignBtnDisabled = true;
      this.performSearch();
      this.searchLoad = false;
    };
  }

  private performSearch() {
    this.isLoading = true;
    const getOrguserandSubExclude = this.organizationService
      .apiV2OrganizationsGetorgusersandsuborgusersexcludeonlicensePost(
        this.rnOrganizationLicensesSearch,
        "response",
      )
      .subscribe(
        (r) => {
          this.currUsers = r.body.data.Results;
          this.assigedLicenseGridConfiguration.loadingDataCompleted(
            r.body.data.Results,
            r.body.data.TotalNumberRecords,
            this.rnOrganizationLicensesSearch.pageNumber *
              this.rnOrganizationLicensesSearch.pageSize,
            this.rnOrganizationLicensesSearch.pageNumber,
          );
          this.isProcessing = false;
          this.searchLoad = false;
          this.isLoading = false;
        },
        (error) => {
          console.error(error);
          this.isProcessing = false;
          this.searchLoad = false;
          this.isLoading = false;
        },
      );
    this.subscriptions.push(getOrguserandSubExclude);
  }

  searchOccurred(searchText: string): void {
    this.searchText = searchText;
    this.rnOrganizationLicensesSearch.search = this.searchText;
    this.rnOrganizationLicensesSearch.pageNumber = 0;
    this.assigedLicenseGridConfiguration.FirstRow = 0;
    this.setLoading();
    this.performSearch();
  }

  private setLoading() {
    if (!this.searchLoad) {
      this.assigedLicenseGridConfiguration.Loading = true;
    }
  }

  AssignLicense() {
    this.isProcessing = true;
    const payloadLicenseIds = [];
    payloadLicenseIds.push(this.data.LicenseID);
    const userIds = [];
    this.selectionColumnConfig.SelectedItems.forEach((u) => {
      const user = u as RnUsersForOrgSubOrgSearchForLicenseVM;
      userIds.push(user.UserID);
    });

    const payload: RnUserLicensesAssignUsers = {
      AffectedOrganizationId: this.data.OrganizationID,
      LicenseIds: payloadLicenseIds,
      UserIds: userIds,
    };
    const lissingAssignPost = this.licenseService
      .apiV2LicensesAssignlicensestousersPost(payload, "response")
      .subscribe((r) => {
        if (r.body.Success)
          this.rnsidebarService.refreshUser(
            this.selectionColumnConfig.SelectedItems.length,
          );
        this.isProcessing = false;
        console.log("User(s) assigned to license successfully");
      });
    this.subscriptions.push(lissingAssignPost);
  }

  dismiss(): void {
    this.rnsidebarService.hide();
  }

  private rnOrganizationLicensesSearch: RnOrganizationUsersForLicenseSearch =
    new RnOrganizationUsersForLicenseSearch();
  private currUsers: RnUsersForOrgSubOrgSearchForLicenseVM[];
  private searchLoad = true;
  private subscriptions: Subscription[] = [];
}
