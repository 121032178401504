import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, Subject } from "rxjs";
import { DataRetrievalParameters } from "../../../../core/models/data-retrieval-parameters";
import { ConstantsService } from "../../../services/constants/constants.service";
import { RnDialogService } from "../../../services/dialog/rndialog.service";
import { LoggedInInfoService } from "../../../services/loggedInInfo/logged-in-info.service";
import { NavigationService } from "../../../services/navigation/navigation.service";
import {
  EntitlementService,
  OrganizationService,
} from "../../../services/rnapi2-service/apis/api";
import {
  RnCommonSearch,
  RnOrganizationPackagePoolsForListVM,
  RnOrganizationsProfileVM,
  RnOrganizationWLPContactInformationVM,
  RnPackagePoolUsersRemove,
  RnUserPackageForListVM,
  RnUserPackageForListVMSearchResultsVM,
} from "../../../services/rnapi2-service/models/models";
import { RnToastService } from "../../../services/toast/rntoast.service";
import { GridCheckboxColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-checkbox-column-configuration";
import { GridColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-column-configuration";
import { GridConfiguration } from "../../third-party-wrappers/grid/configuration/grid-configuration";
import { GridHyperlinkColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-hyperlink-column-configuration";
import { PackageAssignConfigData } from "../package-assignment/package-assign-config-data";
import { PackageConfigInfo } from "../package-inventory/package-config-info";
import { GridMenuColumnConfiguration } from "../../third-party-wrappers/grid/configuration/grid-menu-column-configuration";
import { GenericDialogConfiguration } from "../generic-dialog/generic-dialog-configuration";
import { NotificationDialogService } from "../../../services/notificationDialog/notification-dialog.service";
import { GridMenuItem } from "../../third-party-wrappers/grid/configuration/grid-menu-item";
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "app-package-management",
  templateUrl: "./package-management.component.html",
  styleUrls: ["./package-management.component.scss"],
})
export class PackageManagementComponent implements OnInit, OnDestroy {
  @Input() set PackagePool(pkg: RnOrganizationPackagePoolsForListVM) {
    if (
      this.package?.OrganizationPackagePoolID !== pkg?.OrganizationPackagePoolID
    ) {
      this.package = pkg;
      if (this.package) {
        if (this.runLoadOnPackageAssignment) {
          this.userListParams.AffectedOrganizationId =
            this.package.OrganizationID;
          this.userListParams.Id =
            this.package.OrganizationPackagePoolID.toString();
          this.LoadPackageUsers();
        }
        this.setCanShowChangePackageMix();
        this.RemainingAmount =
          this.package.NumberAllowed - this.package.NumberUsed;
        this.isEntitlementCompletelyConsumed =
          this.package?.NumberUsed >= this.package?.NumberAllowed;
      }
    }
  }
  get PackagePool(): RnOrganizationPackagePoolsForListVM {
    return this.package;
  }

  @Input() SubOrgId?: number;
  @Input() TopLevelOrgId: number;
  @Input() set OrgProfile(profile: RnOrganizationsProfileVM) {
    this.orgProfile = profile;
    this.getInfoText();
  }

  @Input() set OrgWlpContactInfo(info: RnOrganizationWLPContactInformationVM) {
    this.orgWlpContactInfo = info;
    this.getInfoText();
  }
  @Input() set PkgConfigInfo(pkgInfo: PackageConfigInfo) {
    this.pkgConfigInfo = pkgInfo;
    this.getInfoText();
  }

  @Output() PackageChangeOccurred = new EventEmitter<boolean>();

  clearSelection: Subject<void> = new Subject<void>();

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private organizationService: OrganizationService,
    private notificationDlgService: NotificationDialogService,
    private entitlementService: EntitlementService,
    private rnDialogService: RnDialogService,
    private toastService: RnToastService,
    private loggedInInfoService: LoggedInInfoService,
    public dialog: MatDialog,
    private constantsService: ConstantsService,
  ) {}
  private removing = false;
  public CustomerTypePackageText: string;
  public InfoText: string;
  public TableConfiguration = new GridConfiguration();
  public UserList: RnUserPackageForListVMSearchResultsVM;
  public SelectedUsersForRemoval = [];
  public packageAssignTooltip = "";
  public infoText = "";
  public canShowChangePackageMix = false;
  public RemainingAmount: number;
  public canShowAssignRemove = false;
  public isEntitlementCompletelyConsumed = false;
  public orgIsInAddOnSuspended = false;
  public addOnSuspendedTooltip = "";
  ngOnInit(): void {
    const loggedInUser = this.loggedInInfoService.GetLoggedInUser();
    if (!loggedInUser) {
      return;
    }
    this.orgIsInAddOnSuspended =
      this.orgProfile?.Status === "AddOnSuspended" ||
      this.orgProfile?.StatusDesc === "Add-ons Suspended";
    if (this.orgIsInAddOnSuspended) {
      this.addOnSuspendedTooltip =
        "This action is not permitted while your Rightworks account status is Suspended.";
    }
    this.packageAssignTooltip =
      this.constantsService.packageAssignRemoveTooltip;
    this.loggedInUserId = loggedInUser.UserID;

    this.PRCHDROPPK = this.loggedInInfoService.loggedInUserHasRights([
      "PRCHDROPPK",
    ]);
    this.setCanShowChangePackageMix();
    this.VWOTHERSPK = this.loggedInInfoService.loggedInUserHasRights([
      "VWOTHERSPK",
    ]);
    this.ASNRMALLPK = this.loggedInInfoService.loggedInUserHasRights([
      "ASNRMALLPK",
    ]);
    this.ASNRMOWNPK = this.loggedInInfoService.loggedInUserHasRights([
      "ASNRMOWNPK",
    ]);
    this.canShowAssignRemove =
      this.VWOTHERSPK && this.ASNRMALLPK && this.ASNRMOWNPK;

    this.userListParams = {
      pageSize: 10,
      pageNumber: 0,
      OrderByText: "orderByFullName",
      isDescendingOrder: false,
    };

    if (this.package) {
      this.userListParams.AffectedOrganizationId = this.package.OrganizationID;
      this.userListParams.Id =
        this.package.OrganizationPackagePoolID.toString();
    }

    const mapSubscription = this.activatedRoute.paramMap.subscribe((p) => {
      const queryMapSub = this.activatedRoute.queryParamMap.subscribe((m) => {
        const activeTab = m.has("activeTab") ? m.get("activeTab") : "";
        if (activeTab === "packages") {
          const state = m.has("packageState") ? m.get("packageState") : "";
          if (state === "details") {
            //this.userListParams.pageNumber = m.has('pkgUserPageNumber') ? Number(m.get('pkgUserPageNumber')) : 0;
            //this.userListParams.pageSize = m.has('pkgUserPageSize') ? Number(m.get('pkgUserPageSize')) : 10;
            //this.userListParams.isDescendingOrder = m.has('pkgUserIsDescendingOrder') ? m.get('pkgUserIsDescendingOrder') === 'true' : false;
            //this.userListParams.OrderByText = m.has('pkgUserOrderByText') ? m.get('pkgUserOrderByText') : 'orderByLastNameFirstName';
            if (this.package) {
              this.userListParams.AffectedOrganizationId =
                this.package.OrganizationID;
              this.userListParams.Id =
                this.package.OrganizationPackagePoolID.toString();
            } else {
              this.runLoadOnPackageAssignment = true;
            }
          }
        }
      });
      this.subscriptions.push(queryMapSub);
    });

    this.subscriptions.push(mapSubscription);

    this.TableConfiguration.GridClass =
      "tableHeadingColorClass packageAssignUser";
    this.TableConfiguration.FirstRow = 0;
    this.TableConfiguration.CountHeaderItemsName = "Users";
    this.TableConfiguration.getClassForCell = (
      col: GridColumnConfiguration,
      rowData: any,
    ) => {
      return col.Class + (col.FieldName == "UserEmail" ? " break" : "");
    };
    this.TableConfiguration.setUrlData = (params: DataRetrievalParameters) => {
      this.userListParams.pageNumber = params.PageNumber;
      this.userListParams.pageSize = params.PageSize;
      this.userListParams.isDescendingOrder = params.IsDescendingOrder;
      this.userListParams.OrderByText = params.SortOrder
        ? params.SortOrder
        : "orderByFullName";
      this.LoadPackageUsers();
    };

    this.TableConfiguration.ColumnConfiguration = [];
    //Checkbox-selection column
    const selectionCol = new GridCheckboxColumnConfiguration(
      "",
      "",
      "checkboxCol",
    );
    selectionCol.SortableColumnDisabled = true;
    this.TableConfiguration.ColumnConfiguration.push(selectionCol);
    //User's Fullname column
    if (this.loggedInInfoService.loggedInUserHasRights(["VWOTHERSPK"])) {
      const userFullNameCol = new GridHyperlinkColumnConfiguration(
        "FullName",
        "User",
        "userLink",
        "orderByFullName",
      );
      userFullNameCol.GetHref = (rowData: any) => {
        const userData = rowData as RnUserPackageForListVM;
        if (userData) {
          if (this.navigationService) {
            return (
              this.navigationService.GetOpenUserUrl(
                userData.UserID,
                userData.OrganizationID,
              )
            );
          }
        }
        return "";
      };
      this.TableConfiguration.ColumnConfiguration.push(userFullNameCol);
    } else {
      this.TableConfiguration.ColumnConfiguration.push(
        new GridColumnConfiguration(
          "FullName",
          "User",
          "userLink",
          "orderByFullName",
        ),
      );
    }

    //User's Email column
    const emailCol = new GridColumnConfiguration(
      "UserEmail",
      "Email Address",
      "pkg-user-list-email-col",
      "orderByEmail",
    );
    this.TableConfiguration.ColumnConfiguration.push(emailCol);
    //User's Organization column
    const orgNameCol = new GridHyperlinkColumnConfiguration(
      "OrganizationName",
      "Organization",
      "userLink",
      "orderByOrganizationName",
    );
    orgNameCol.GetHref = (rowData: any) => {
      const userData = rowData as RnUserPackageForListVM;
      if (userData) {
        if (this.navigationService) {
          return (
            "/#/" +
            this.navigationService.GetOpenOrgUserUrl(
              this.TopLevelOrgId,
              userData.OrganizationID,
              "info",
            )
          );
        }
      }
      return "";
    };
    this.TableConfiguration.ColumnConfiguration.push(orgNameCol);

    //User's Role column
    const roleCol = new GridColumnConfiguration(
      "UserRole",
      "Role",
      "pkg-user-list-role-col",
      "orderByUserTypeName",
    );
    this.TableConfiguration.ColumnConfiguration.push(roleCol);

    // Actions column
    const removeMenuItem = new GridMenuItem(
      "remove-user-from-package",
      "Remove Package From User",
      (rowData: any, _: MatMenuTrigger) => {
        this.usersToRemove = [rowData];
        this.removeUsersFromPackage();
      },
      "removeUser",
      "rn-user-x",
    );
    const actionsCol = new GridMenuColumnConfiguration(
      "",
      "Actions",
      "pkg-user-list-action-col",
      "",
      [removeMenuItem],
    );
    actionsCol.SortableColumnDisabled = true;
    actionsCol.Tooltip = (_: any) => {
      return "Remove this user from package";
    };
    this.TableConfiguration.ColumnConfiguration.push(actionsCol);
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  public AssignPackage(): void {
    const config = new PackageAssignConfigData();
    config.OrgProfile = this.orgProfile;
    config.OrgWlpContactInfo = this.orgWlpContactInfo;
    config.Package = this.package;
    config.PkgConfigInfo = this.pkgConfigInfo;
    config.TopLevelOrgID = this.TopLevelOrgId;
    const dialogRef = this.rnDialogService.PackageAssignmentDialog(config);
    dialogRef.onClose.subscribe((r: number) => {
      if (r > 0) {
        this.PackageChangeOccurred.emit(true);
        this.toastService.showSuccess("Package assigned successfully");
        this.TableConfiguration.Loading = true;
        setTimeout(() => {
          this.reloadAllData();
        }, 5000 * r);
      }
    });
  }

  public areUsersSelected(): boolean {
    return this.SelectedUsersForRemoval.length > 0;
  }

  public async changePackageMix(): Promise<void> {
    const queryParams = {
      packageState: "purchase",
    };
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge",
    });
  }

  public removeSelectedPackages(): void {
    this.usersToRemove = [];
    this.SelectedUsersForRemoval.forEach((u) => {
      this.usersToRemove.push(u);
    });
    if (this.removeUsersFromPackage()) {
      this.LoadPackageUsers();
    }
  }

  public async returnToInventory(): Promise<void> {
    const queryParams = {
      packageState: null,
      packagePoolId: null,
    };
    await this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: "merge", // remove to replace all query params by provided
    });
  }

  public rowSelected(event): void {
    this.SelectedUsersForRemoval = event;
  }

  private orgProfile: RnOrganizationsProfileVM;
  private orgWlpContactInfo: RnOrganizationWLPContactInformationVM;
  private pkgConfigInfo: PackageConfigInfo;
  private userListParams: RnCommonSearch;
  private subscriptions = [];
  private package: RnOrganizationPackagePoolsForListVM;
  private runLoadOnPackageAssignment = false;
  private loggedInUserId: number;
  private usersToRemove: RnUserPackageForListVM[];
  private PRCHDROPPK: boolean;
  private VWOTHERSPK: boolean;
  private ASNRMALLPK: boolean;
  private ASNRMOWNPK: boolean;

  private LoadPackageUsers() {
    if (this.PackagePool) {
      this.subscriptions.push(
        this.organizationService
          .apiV2OrganizationsGetusersfororganizationpackagepoolPost(
            this.userListParams,
            "response",
          )
          .subscribe((r) => {
            this.setUserListData(r.body.data);
          }),
      );
    }
  }

  private async setNavigationInfo(): Promise<void> {
    const pkgUserPageNumber = this.activatedRoute.snapshot.queryParamMap.has(
      "pkgUserPageNumber",
    )
      ? this.activatedRoute.snapshot.queryParamMap.get("pkgUserPageNumber")
      : "";
    const pkgUserPageSize = this.activatedRoute.snapshot.queryParamMap.has(
      "pkgUserPageSize",
    )
      ? this.activatedRoute.snapshot.queryParamMap.get("pkgUserPageSize")
      : "";
    const pkgUserIsDescendingOrder =
      this.activatedRoute.snapshot.queryParamMap.has("pkgUserIsDescendingOrder")
        ? this.activatedRoute.snapshot.queryParamMap.get(
            "pkgUserIsDescendingOrder",
          )
        : "";
    const pkgUserOrderByText = this.activatedRoute.snapshot.queryParamMap.has(
      "pkgUserOrderByText",
    )
      ? this.activatedRoute.snapshot.queryParamMap.get("pkgUserOrderByText")
      : "";

    if (
      this.userListParams.pageNumber.toString() !== pkgUserPageNumber ||
      this.userListParams.pageSize?.toString() !== pkgUserPageSize ||
      this.userListParams.isDescendingOrder?.toString() !==
        pkgUserIsDescendingOrder ||
      this.userListParams.OrderByText !== pkgUserOrderByText
    ) {
      if (
        this.userListParams.isDescendingOrder?.toString() !==
          pkgUserIsDescendingOrder ||
        this.userListParams.OrderByText !== pkgUserOrderByText ||
        this.userListParams.pageSize?.toString() !== pkgUserPageSize
      ) {
        this.userListParams.pageNumber = 0;
      }
      const queryParams = {
        pkgUserPageSize: this.userListParams.pageSize,
        pkgUserPageNumber: this.userListParams.pageNumber,
        pkgUserOrderByText:
          typeof this.userListParams.OrderByText !== "undefined"
            ? this.userListParams.OrderByText
            : "orderByFullName",
        pkgUserIsDescendingOrder: this.userListParams.isDescendingOrder,
      };

      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: "merge", // remove to replace all query params by provided
      });
    } else {
      this.LoadPackageUsers();
    }
  }

  private reloadAllData(pageNumber?: number): void {
    const getPackPoolPayload = new RnCommonSearch();
    getPackPoolPayload.AffectedOrganizationId = this.orgProfile.ID;
    getPackPoolPayload.Id = this.package.OrganizationPackagePoolID.toString();

    this.userListParams.pageNumber = pageNumber ? pageNumber : 0;

    forkJoin(
      this.organizationService.apiV2OrganizationsGetusersfororganizationpackagepoolPost(
        this.userListParams,
        "response",
      ),
      this.organizationService.apiV2OrganizationsGetorganizationpackagepoolPost(
        getPackPoolPayload,
        "response",
      ),
    ).subscribe(([userListResponse, orgPackPoolResponse]) => {
      this.PackagePool.NumberAllowed =
        orgPackPoolResponse.body.data.NumberAllowed;
      this.PackagePool.NumberUsed = orgPackPoolResponse.body.data.NumberUsed;
      this.RemainingAmount =
        this.package.NumberAllowed - this.package.NumberUsed;
      this.isEntitlementCompletelyConsumed =
        this.package?.NumberUsed >= this.package?.NumberAllowed;
      this.setUserListData(userListResponse.body.data);
      this.PackageChangeOccurred.emit(false);
    });
  }

  private setUserListData(
    userListData: RnUserPackageForListVMSearchResultsVM,
  ): void {
    this.UserList = userListData;

    const firstRow =
      this.userListParams.pageNumber * this.userListParams.pageSize;
    this.TableConfiguration.loadingDataCompleted(
      this.UserList.Results,
      this.UserList.TotalNumberRecords,
      firstRow,
      this.userListParams.pageNumber,
    );
  }

  private removeUsersFromPackage(): boolean {
    this.removing = true;
    const confirmConfig = new GenericDialogConfiguration();
    confirmConfig.StyleClass = "confirmation removeUsers";
    confirmConfig.Title = "Remove Packages?";
    confirmConfig.Message = `Are you sure you want to remove this package from this user?`;
    confirmConfig.ConfirmButtonStyleClass = "primary";
    confirmConfig.CancelButtonStyleClass = "cancelButton";
    confirmConfig.DialogHeaderClass = "modal-header no-border";
    confirmConfig.DialogFooterCancelClass = "right-spacing";
    confirmConfig.cancelButtonNoContentPadding = true;
    confirmConfig.confirmButtonNoContentPadding = true;
    confirmConfig.ConfirmButtonText = "CONFIRM";
    confirmConfig.MessageContainsHTML = true;
    confirmConfig.ShowCloseButton = false;
    confirmConfig.Confirmed = () => {
      this.PackageChangeOccurred.emit(true);
      this.TableConfiguration.Loading = true;
      const payload = new RnPackagePoolUsersRemove();
      payload.AffectedOrganizationId = this.package.OrganizationID;
      payload.AffectedUserId = this.loggedInUserId;
      payload.UserPackagePool_Ids = [];
      this.usersToRemove.forEach((u) => {
        payload.UserPackagePool_Ids.push(u.UserPackageID);
      });

      this.entitlementService
        .apiV2EntitlementRemovepackagefromusersPost(payload, "response")
        .subscribe((r) => {
          if (r.body.Success) {
            this.toastService.showSuccess(
              (this.usersToRemove.length > 1 ? "Users" : "User") +
                " removed successfully",
            );
            const removeUsersLength = this.usersToRemove.length;
            setTimeout(() => {
              this.usersToRemove = [];
              this.SelectedUsersForRemoval = [];
              this.clearSelection.next();

              if (this.checkTableLength(removeUsersLength)) {
                this.reloadAllData(this.userListParams.pageNumber);
              } else {
                this.reloadAllData();
              }
            }, 5000 * this.usersToRemove.length);
          }
        });
    };
    confirmConfig.Canceled = () => {
      this.removing = false;
    };
    this.notificationDlgService.ShowConfirmation(confirmConfig);
    return this.removing;
  }

  checkTableLength(removedRecords) {
    return removedRecords !== this.UserList?.Results?.length;
  }

  public getInfoText(): void {
    if (this.orgProfile && this.pkgConfigInfo && this.orgWlpContactInfo) {
      switch (this.orgProfile.CustomerType) {
        case "Intuit_PCG":
          this.infoText = `To purchase additional seats, please contact ${this.pkgConfigInfo.ProseriesLacerteSalesNumber}.`;
          break;
        case "Intuit":
          this.infoText = `Please contact Intuit Sales at ${this.pkgConfigInfo.IntuitSalesNumber} to purchase additional hosting packages.`;
          break;
        case "Drake":
          this.infoText = `To purchase additional seats, please contact ${this.orgWlpContactInfo.SalesPhone}.`;
          break;
        default:
          this.infoText = null;
          break;
      }
    }
  }

  private setCanShowChangePackageMix() {
    this.canShowChangePackageMix =
      this.PRCHDROPPK && this.package?.IsPurchasable;
  }
}
